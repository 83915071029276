import React from "react";
import MasterLayout from "../components/masterLayout";

const NotFoundPage = () => (
  <MasterLayout title="404: Not found">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MasterLayout>
);
export default NotFoundPage;
